import React from 'react';
import styled from 'styled-components';
import getIcon from '@empiriecom/module-components/utils/getIcon';

const StyledChip = styled.div`
  display: inline-block;
  padding: 0.125rem 0.25rem;
  border: 1px solid ${({ theme }) => theme.success.color.normal};
  border-radius: 16px;
  background-color: transparent;
  color: ${({ theme }) => theme.success.color.normal};
  font-size: 0.688rem;
  line-height: 1;
`;

const Checkmark = getIcon('Checkmark');
const StyledCheckmark = styled(Checkmark)`
  margin-top: -2px;
  margin-right: 0.5rem;
  fill: ${({ theme }) => theme.success.color.normal};
`;

interface ChipProps {
  hasCheckmark?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const Chip: React.FC<ChipProps> = ({ hasCheckmark, className, children }) => (
  <StyledChip className={className}>
    {hasCheckmark && <StyledCheckmark data-testid="checkmark" size="1rem" />}
    {children}
  </StyledChip>
);

export default Chip;
