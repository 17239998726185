import React, { useEffect } from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import useLoginApi from '@empiriecom/mybuy-components/api/useLoginApi';
import Notification from '@empiriecom/module-components/Notification';
import { defineMessages, useIntl } from 'react-intl';
import { ChangePassword } from '@/src/components/ChangePassword';
import { ForgotPasswordModalContent } from '@empiriecom/mybuy-components/components/LoginContainer/ForgotPassword/ForgotPasswordModal';
import { useHistory, useLocation } from 'react-router';
import { useNotificationContext } from '@empiriecom/module-components/Notification/NotificationProvider';
import { apiKeyManager } from '@empiriecom/mybuy-session/ApiKey';
import { internalPages } from '@/config/pages';
import ForgotPasswordContainerSkeleton from './skeleton';
import EventTracking from '../EventTracking';

const messages = defineMessages({
  generalError: {
    id: 'PasswordForget.error.general',
    defaultMessage:
      'Ihre Anfrage kann gerade nicht bearbeitet werden. Versuchen Sie es später erneut.',
  },
  pwChangeSuccess: {
    id: 'PasswordForget.password.change.success',
    defaultMessage: 'Passwort erfolgreich geändert.',
  },
});

const ForgotPasswordWrapper = styled.div`
  display: block;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const ForgotPasswordContainer = () => {
  const { formatMessage, locale } = useIntl();
  const api = useLoginApi();
  const history = useHistory();
  const location = useLocation();
  const { pushNotification } = useNotificationContext();

  const [pwForgetEmail, setPwForgetEmail] = React.useState<string | null>(null);
  const [pwForgetKey, setPwForgetKey] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [isTokenValid, setIsTokenValid] = React.useState<boolean>(false);

  const handleTokenValidation = async () => {
    if (api && pwForgetKey) {
      try {
        setError(null);
        const response = await api.forgotPasswordValidateToken({
          ecLocale: locale,
          forgotPasswordValidateTokenRequestPayload: {
            validateLostPasswordToken: pwForgetKey,
          },
        });
        if (response.success) {
          setIsTokenValid(true);
        }
      } catch {
        setError(formatMessage(messages.generalError));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const customChangePw = async (newPassword: string) => {
    try {
      setError(null);
      /* istanbul ignore else - type safe check but customer should never get here */
      if (api && pwForgetKey) {
        const result = await api.forgotPasswordSetNewPassword({
          ecLocale: locale,
          forgotPasswordSetNewPasswordRequestPayload: {
            validateLostPasswordToken: pwForgetKey,
            newPassword,
          },
        });
        if (result.loginResult?.tokens) {
          apiKeyManager.setApiKeys(result.loginResult.tokens);
        }
        pushNotification({
          level: 'success',
          hasClose: true,
          autoClose: 3000,
          content: formatMessage(messages.pwChangeSuccess),
        });
        const myAccountLink =
          locale.split('-')[0] === 'fr'
            ? internalPages.overview.paths.fr
            : internalPages.overview.paths.de;
        history.push(myAccountLink);
      }
    } catch {
      setError(formatMessage(messages.generalError));
    }
  };

  useEffect(() => {
    const parsedQuery = qs.parse(location.search);
    setPwForgetEmail(parsedQuery.email as string | null);
    setPwForgetKey(parsedQuery.key as string | null);
  }, [location]);

  useEffect(() => {
    handleTokenValidation();
  }, [api, pwForgetEmail, pwForgetKey]);

  return (
    <ForgotPasswordWrapper>
      <EventTracking eventCategory="login_resetPassword" isLayer />
      {error && <Notification level="error" isInline content={error} />}
      {isLoading && <ForgotPasswordContainerSkeleton />}
      {!isLoading && isTokenValid && (
        <div data-testid="forgot-password-valid-token">
          <ChangePassword customSubmit={customChangePw} />
        </div>
      )}
      {!isLoading && !isTokenValid && (
        <div data-testid="forgot-password-invalid-token">
          <ForgotPasswordModalContent isTokenInvalid hideErrors onError={setError} />
        </div>
      )}
    </ForgotPasswordWrapper>
  );
};

export default ForgotPasswordContainer;
